<template>
  <referentiel-list
    :listTitle="'Liste des Types de prescriptions'"
    :referentielName="referentielName"
    :tableFields="tableFields"
    :emptyReferentielItem="emptyReferentielItem"
    :dataFormatter="dataFormatter"
    @activeReferentielChange="handleActiveReferentielChanged"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.nom"
                @input="handleInput"
                type="text"
                class="form-control"
                id="nom"
                name="nom"
                placeholder="nom"
              />
              <label for="nom">Nom</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.description"
                @input="handleInput"
                type="text"
                class="form-control"
                id="description"
                name="description"
                placeholder="Description"
              />
              <label for="description">Description</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";

export default {
  data() {
    return {
      items: [],
    };
  },
  components: {
    ReferentielList,
  },
  computed: {
    // ...mapGetters({
    //   privileges: "privilege/privileges",
    // }),
    referentielName() {
      return "domaine";
    },
    emptyReferentielItem() {
      return {
        setting: null,
      };
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "description", label: "Description" },

        "actions",
      ];
    },
    selection() {
      return [[], []];
    },
  },
  watch: {},
  created() {},
  methods: {
    // ...mapActions({
    //   fetchPrivileges: "privilege/fetchPrivileges",
    // }),
    dataFormatter(item) {
      item = {
        ...item,
        name:
          item.name && item.name.toUpperCase().startsWith("ROLE_")
            ? item.name.toUpperCase()
            : "ROLE_" + item.name.toUpperCase(),
        privileges: this.items[1],
      };
      return item;
    },
    handleActiveReferentielChanged(item) {
      const itemIds = item.privileges.map((el) => el.id);
      this.items = [
        this.privileges.filter((it) => !itemIds.includes(it.id)),
        item.privileges,
      ];
    },
  },
};
</script>

<style></style>
